import React, { useState, useEffect } from "react";
import { useLocalStorage } from "@uidotdev/usehooks";
import {
  Box,
  Typography,
  OutlinedInput,
  CssBaseline,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import AnimatedBackground from "./backgrounds/AnimatedBackground";
import CasinoIcon from "@mui/icons-material/Casino";
import PersonalizeUI from "./Personalize";
import { trackEvent } from "../utils/Mixpanel";
import { useMediaQuery, useTheme } from "@mui/material";

const placeholderStrings = [
  "Unicorn with stars and a rainbow in the background...",
  "Cute kitten playing with a ball of yarn",
  "Let your imagination run wild...",
  "Mermaid swimming in an underwater kingdom with colorful fish",
  "Friendly yeti building a snowman in the mountains",
  "Pegasus soaring through the clouds with a golden mane",
  "Centaur archer aiming an arrow in an enchanted forest",
  "Adorable penguin sliding down an ice slide",
  "Sleepy koala hugging a eucalyptus tree",
  "Fluffy bunny with big ears hopping through a meadow",
  "Tiny mouse nibbling on a piece of cheese under a picnic blanket",
  "Lovable panda cuddling with a teddy bear",
  "Cute baby elephant splashing water with its trunk in a pond",
  "Fluffy bunny wearing a tiny bowtie and holding a carrot",
  "Charming panda cub playing with a ball of bamboo leaves",
  "Sweet little koala hugging its mother on a eucalyptus tree",
  "Playful kitten chasing butterflies in a sunny garden",
  "Cheerful penguin sliding on an ice floe under the northern lights",
  "Joyful dolphin jumping through hoops in the ocean",
  "Smiling fox wearing a little hat and scarf, sitting in a pumpkin patch",
  "Friendly raccoon holding a bunch of balloons at a birthday party",
  "Tiny mouse sipping tea from a tiny teacup in a cozy mouse house",
  "Adorable bear cub snuggled up with a blanket and teddy bear",
  "Sleepy puppy curled up in a basket with a soft blanket",
  "Chubby hamster holding a sunflower seed with its little paws",
  "Cute chicks pecking at the ground in a flowery meadow",
];

const Home: React.FC = () => {
  const [inputValue, setInputValue] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [showPersonalizeUI, setShowPersonalizeUI] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [placeholder, setPlaceholder] = useState<string>("");
  const [placeholderIndex, setPlaceholderIndex] = useState<number>(0);
  const [personalizeDisabled, setPersonalizeDisabled] = useState<boolean>(true);

  const [, setPersonalizedText] = useState<string>("");
  const [, setPersonalizedPosition] = useState<string>("");

  const [, setToken] = useLocalStorage("token", null);
  const [, setPrompt] = useLocalStorage("prompt", "");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    trackEvent("home_page_entry");
  }, []);

  useEffect(() => {
    if (inputValue === "") {
      const interval = setInterval(() => {
        setPlaceholder((prev) => {
          const currentString = placeholderStrings[placeholderIndex];
          if (prev.length < currentString.length) {
            return currentString.slice(0, prev.length + 1);
          } else {
            clearInterval(interval);
            return prev;
          }
        });
      }, 100);

      return () => clearInterval(interval);
    }
  }, [inputValue, placeholderIndex]);

  useEffect(() => {
    if (inputValue === "") {
      const timeout = setTimeout(() => {
        setPlaceholderIndex(
          (prevIndex) => (prevIndex + 1) % placeholderStrings.length
        );
        setPlaceholder("");
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [placeholder, inputValue]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.target.value.length > 0) {
      setPersonalizeDisabled(false);
    } else {
      setPersonalizeDisabled(true);
    }
    setInputValue(event.target.value);
  };

  const handleCheckout = async () => {
    trackEvent(`home_user_prompt: ${inputValue}`);
    trackEvent("home_clicked_create_button");
    setLoading(true);

    const data = await fetch("/api/links");
    const json = await data.json();

    setPrompt(inputValue);
    setToken(json.data.token);

    window.location.href = json.data.url;
  };

  const randomStrings: string[] = [
    "Pirate themed bird with a pirate hat, peg leg and eye patch",
    "Unicorn with stars and a rainbow in the background",
    "Cute kitten playing with a ball of yarn",
    "Photorealistic man coming back to 2024 from the future to warn humanity of GPT-6",
    "Let your imagination run wild...",
    "Mermaid swimming in an underwater kingdom with colorful fish",
    "Friendly yeti building a snowman in the mountains",
    "Pegasus soaring through the clouds with a golden mane",
    "Centaur archer aiming an arrow in an enchanted forest",
    "Adorable penguin sliding down an ice slide",
    "Sleepy koala hugging a eucalyptus tree",
    "Fluffy bunny with big ears hopping through a meadow",
    "Tiny mouse nibbling on a piece of cheese under a picnic blanket",
    "Lovable panda cuddling with a teddy bear",
    "Cute baby elephant splashing water with its trunk in a pond",
    "Fluffy bunny wearing a tiny bowtie and holding a carrot",
    "Charming panda cub playing with a ball of bamboo leaves",
    "Sweet little koala hugging its mother on a eucalyptus tree",
    "Playful kitten chasing butterflies in a sunny garden",
    "Cheerful penguin sliding on an ice floe under the northern lights",
    "Joyful dolphin jumping through hoops in the ocean",
    "Smiling fox wearing a little hat and scarf, sitting in a pumpkin patch",
    "Friendly raccoon holding a bunch of balloons at a birthday party",
    "Tiny mouse sipping tea from a tiny teacup in a cozy mouse house",
    "Adorable bear cub snuggled up with a blanket and teddy bear",
    "Sleepy puppy curled up in a basket with a soft blanket",
    "Chubby hamster holding a sunflower seed with its little paws",
    "Cute chicks pecking at the ground in a flowery meadow",
  ];

  const handleDiceTap = () => {
    trackEvent("home_clicked_randomize_button");

    const getRandomString = () => {
      const randomIndex = Math.floor(Math.random() * randomStrings.length);
      return randomStrings[randomIndex];
    };

    if (inputValue === "") {
      setInputValue(getRandomString());
      setPersonalizeDisabled(false);
    } else if (!randomStrings.includes(inputValue)) {
      setShowDialog(true);
    } else {
      setPersonalizeDisabled(false);
      setInputValue(getRandomString());
    }
  };

  const handlePersonalizeClick = () => {
    trackEvent("home_clicked_personalize_button");
    setShowPersonalizeUI(!showPersonalizeUI);
  };

  const handleAddPersonalization = (text: string, position: string) => {
    setPersonalizedText(text);
    setPersonalizedPosition(position);

    // Only insert if the user has entered text
    if (text === "") {
      return;
    }

    const currentText = inputValue.trim();
    const cleanedText = text.trim();
    const cleanedPosition = position.trim();
    const newString =
      currentText +
      " with the text " +
      `"${cleanedText}"` +
      " positioned at the " +
      cleanedPosition.toLocaleLowerCase();
    setInputValue(newString);
  };

  const handleDialogClose = () => {
    trackEvent("home_generate_random_popup_clicked_no");
    setShowDialog(false);
  };

  const handleDialogConfirm = () => {
    trackEvent("home_generate_random_popup_clicked_yes");
    const randomIndex = Math.floor(Math.random() * randomStrings.length);
    const randomString = randomStrings[randomIndex];
    setInputValue(randomString);
    setShowDialog(false);
  };

  return (
    <AnimatedBackground>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          paddingTop: "180px",
          width: "70%",
          margin: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" gutterBottom>
            {process.env.REACT_APP_NAME}
          </Typography>

          <Typography variant="subtitle1" sx={{ mb: 2 }}>
            {process.env.REACT_APP_DESCRIPTION}
          </Typography>

          <OutlinedInput
            fullWidth
            placeholder={inputValue === "" ? placeholder : ""}
            multiline
            rows={3}
            value={inputValue}
            onChange={handleInputChange}
            sx={{
              mb: 2,
              minHeight: "100px",
              backgroundColor: "white",
              "& .MuiInputBase-input::placeholder": {
                fontSize: isMobile ? "0.95rem" : "1.2rem",
                fontStyle: "italic",
                color: "black",
              },
            }}
          />

          <Box
            display={"flex"}
            flexDirection={isMobile ? "column" : "row"}
            gap={2}
            alignItems={"center"}
          >
            <LoadingButton
              disabled={personalizeDisabled}
              variant="contained"
              loading={loading}
              onClick={handleCheckout}
              sx={{ fontWeight: "600" }}
            >
              Create
            </LoadingButton>

            <Button
              variant="contained"
              disabled={personalizeDisabled}
              sx={{
                backgroundColor: "#FDDE55",
                boxShadow: "none",
                color: "black",
                fontWeight: "600",
                "&:hover": {
                  backgroundColor: "#FEB941",
                },
              }}
              onClick={handlePersonalizeClick}
            >
              Personalize
            </Button>
            <IconButton
              onClick={handleDiceTap}
              sx={{
                width: "40px",
                height: "40px",
                backgroundColor: "transparent",
              }}
            >
              <CasinoIcon
                sx={{ width: "48px", height: "48px", color: "#A34343" }}
              />
            </IconButton>
          </Box>

          {showPersonalizeUI && (
            <PersonalizeUI
              onClose={handlePersonalizeClick}
              onAdd={handleAddPersonalization}
            />
          )}
        </Box>

        <Dialog
          open={showDialog}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle textAlign={"center"} id="alert-dialog-title">
            {"Generate a New Idea?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              textAlign={"center"}
              id="alert-dialog-description"
            >
              Tapping the dice button will overwrite your current idea with a
              new suggestion. Are you sure?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
              variant="contained"
              onClick={handleDialogClose}
              color="primary"
            >
              No
            </Button>
            <Button
              variant="contained"
              onClick={handleDialogConfirm}
              color="primary"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        {!isMobile && (
          <Box>
            <Typography
              variant="caption"
              sx={{
                position: "absolute",
                bottom: "30px",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              Created by{" "}
              <a
                href="https://prostackdev.com"
                target="_blank"
                rel="noreferrer"
              >
                Pro Stack
              </a>
            </Typography>
          </Box>
        )}
      </Box>
    </AnimatedBackground>
  );
};

export default Home;
