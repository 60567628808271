import React, { useState } from "react";
import { Box, OutlinedInput, MenuItem, Select, Button } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { trackEvent } from "../utils/Mixpanel";

interface PersonalizeUIProps {
  onClose: () => void;
  onAdd: (text: string, position: string) => void;
}

const PersonalizeUI: React.FC<PersonalizeUIProps> = ({ onClose, onAdd }) => {
  const [text, setText] = useState<string>("");
  const [position, setPosition] = useState<string>("Top Left");

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const handlePositionChange = (event: SelectChangeEvent<string>) => {
    trackEvent("personalize_clicked_change_location_dropdown");
    setPosition(event.target.value);
  };

  const handleAddClick = () => {
    trackEvent("personalize_clicked_add_button");
    trackEvent(`personalize_user_prompt: ${text}`);

    onAdd(text, position);
    onClose();
  };

  return (
    <Box
      sx={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
        marginTop: 2,
        padding: "15px",
        borderRadius: "8px",
        border: "1px solid black",
        width: "100%",
        maxWidth: "600px",
      }}
    >
      <Box
        sx={{ display: "flex", width: "100%", gap: 2, alignItems: "center" }}
      >
        <OutlinedInput
          value={text}
          onChange={handleTextChange}
          placeholder="Add: ie. Madison or Audrey"
          sx={{ flexGrow: 1, backgroundColor: "white" }}
        />
        <Select
          value={position}
          onChange={handlePositionChange}
          sx={{ backgroundColor: "white" }}
        >
          <MenuItem value="Top Left">Top Left</MenuItem>
          <MenuItem value="Top Right">Top Right</MenuItem>
          <MenuItem value="Bottom Left">Bottom Left</MenuItem>
          <MenuItem value="Bottom Right">Bottom Right</MenuItem>
        </Select>
      </Box>
      <Button
        variant="contained"
        onClick={handleAddClick}
        sx={{
          boxShadow: "none",
          alignSelf: "flex-end",
          backgroundColor: "#6DC5D1",
          fontWeight: "bold",
          color: "black",
          "&:hover": {
            backgroundColor: "#03AED2",
          },
        }}
      >
        {text !== "" ? "Add" : "Enter Prompt"}
      </Button>
    </Box>
  );
};

export default PersonalizeUI;
