import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Button,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

import Lottie from "lottie-react";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "@uidotdev/usehooks";
import { AsyncImage } from "loadable-image";

import imageError from "../animations/error-anim.json";
import imageLoader from "../animations/generic-loader-anim.json";
import { trackEvent } from "../utils/Mixpanel";

interface ImageCellProps {
  imageUrl: string;
  onPrint: () => void;
  onSave: () => void;
}

const ErrorImage: React.FC = () => {
  return (
    <Lottie
      loop={false}
      animationData={imageError}
      style={{ margin: "auto", width: "100%", height: "100%" }}
    />
  );
};

const PlaceholderImage: React.FC = () => {
  return (
    <Lottie
      loop={true}
      animationData={imageLoader}
      style={{ margin: "auto", width: "100%", height: "100%" }}
    />
  );
};

const ImageCell: React.FC<ImageCellProps> = ({ imageUrl, onPrint, onSave }) => {
  const handleContextMenu = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  return (
    <Paper
      elevation={3}
      sx={{
        minWidth: 550,
        maxWidth: 550,
        height: 777,
        position: "relative",
        margin: 2,
        overflow: "hidden",
        borderRadius: "2rem",
        "@media (max-width: 600px)": {
          minWidth: 300,
          maxWidth: 300,
          height: 400,
        },
      }}
    >
      <AsyncImage
        src={imageUrl}
        alt="Result Image"
        error={<ErrorImage />}
        loader={<PlaceholderImage />}
        onContextMenu={handleContextMenu}
        style={{ width: "100%", height: "100%", objectFit: "contain" }}
      />

      <Box
        sx={{
          position: "absolute",
          bottom: 30,
          left: "50%",
          transform: "translateX(-50%)",
          display: "flex",
          justifyContent: "center",
          gap: 4,
        }}
      >
        <Button
          onClick={onPrint}
          variant="contained"
          color="primary"
          sx={{ width: 50, height: 50 }}
        >
          Print
        </Button>

        {/* <Button
          onClick={onSave}
          variant="contained"
          sx={{ width: 50, height: 50, minWidth: 50 }}
        >
          <DownloadIcon />
        </Button> */}
      </Box>
    </Paper>
  );
};

interface ResultsProps {
  images: string[];
}

const Results: React.FC<ResultsProps> = ({ images }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();

  const [, setToken] = useLocalStorage("token", null);
  const [prompt, setPrompt] = useLocalStorage("prompt", "");

  useEffect(() => {
    trackEvent("results_page_entry");
  }, []);

  const handleDownload = (url: string) => {
    trackEvent("results_clicked_download_button");

    fetch(url, { mode: "no-cors" })
      .then((response) => response.blob())
      .then((blob) => {
        const timestamp = new Date().getTime();
        const file = new Blob([blob], { type: "image/jpeg" });
        const a = document.createElement("a");
        a.href = URL.createObjectURL(file);
        a.download = `mindpaint-${timestamp}.jpg`;
        document.body.appendChild(a);
        a.style.display = "none";
        a.click();
        a.remove();
        URL.revokeObjectURL(a.href);
      })
      .catch((e) => console.error("Error in downloading file: ", e));
  };

  const handlePrint = (url: string) => {
    trackEvent("results_clicked_print_button");

    const printWindow = window.open("", "_blank");

    if (printWindow) {
      printWindow.document.write(
        `<html>
          <head>
            <title>Your Custom Coloring Page</title>
          </head>
          
          <body style="text-align: center;">
            <img src="${url}" onload="window.print();window.close();" style="max-width: 100%;">
            <p>Created with MindPaint.app</p>
            <p>${prompt}</p>
          </body>
        </html>`
      );

      printWindow.document.close();
      
    } else {
      alert("Please disable any popup blockers.");
    }
  };

  const handleCancelButton = () => {
    trackEvent("results_clicked_cancel_button");
    setOpen(true);
  };

  const handleHome = () => {
    setToken(null);
    setPrompt("");
    navigate("/");
  };

  const handleCloseDialogue = () => {
    trackEvent("results_cancel_popup_clicked_cancel");
    setOpen(false);
  };

  const handleOpenDialogue = () => {
    trackEvent("results_cancel_popup_clicked_confirm");
    setOpen(false);
    handleHome();
  };

  return (
    <Box sx={{ width: "100vw", height: "100vh", overflow: "hidden" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          p: 4,
        }}
      >
        <IconButton
          onClick={handleCancelButton}
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: "white",
            "&:hover": {
              backgroundColor: theme.palette.secondary.main,
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Dialog
        open={open}
        style={{ zIndex: 9999 }}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText
            sx={{ margin: "10px", color: theme.palette.primary.main }}
            variant="subtitle1"
            id="alert-dialog-description"
          >
            Are you sure you want to exit?
          </DialogContentText>

          <DialogContentText
            sx={{ color: theme.palette.primary.main }}
            variant="subtitle1"
            id="alert-dialog-description"
          >
            You will not be able to print or save these again.
          </DialogContentText>
        </DialogContent>

        <DialogActions sx={{ justifyContent: "center" }}>
          <Button variant="contained" onClick={handleCloseDialogue}>
            Cancel
          </Button>

          <Button variant="contained" onClick={handleOpenDialogue} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        sx={{
          display: "flex",
          overflowX: "auto",
          p: 2,
          gap: 2,
          alignItems: "center",
          "@media (max-width: 600px)": {
            flexDirection: "row",
            overflowY: "auto",
            height: "calc(100vh - 25%)",
          },
        }}
      >
        {images.map((image, index) => (
          <ImageCell
            key={index}
            imageUrl={image}
            onPrint={() => handlePrint(image)}
            onSave={() => handleDownload(image)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Results;
