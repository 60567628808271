/**
 * Modified and converted from https://codepen.io/kootoopas/pen/kGPoaB
 */

import React from "react";
import { CssBaseline, styled } from "@mui/material";
import { keyframes } from "@mui/system";

const bgScrollingReverse = keyframes`
  100% { background-position: 50px 50px; }
`;

const StyledAnimatedBackground = styled("div")({
  color: "#999",
  fontFamily: 'Exo, ubuntu, "segoe ui", helvetica, arial, sans-serif',
  textAlign: "center",
  background:
    'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAIAAACRXR/mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAABnSURBVHja7M5RDYAwDEXRDgmvEocnlrQS2SwUFST9uEfBGWs9c97nbGtDcquqiKhOImLs/UpuzVzWEi1atGjRokWLFi1atGjRokWLFi1atGjRokWLFi1atGjRokWLFi1af7Ukz8xWp8z8AAAA//8DAJ4LoEAAlL1nAAAAAElFTkSuQmCC") repeat 0 0',
  animation: `${bgScrollingReverse} 1s infinite linear`,
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
});

interface AnimatedBackgroundProps {
  children: React.ReactNode;
}

const AnimatedBackground: React.FC<AnimatedBackgroundProps> = ({
  children,
}) => {
  return (
    <StyledAnimatedBackground>
      <CssBaseline />
      {children}
    </StyledAnimatedBackground>
  );
};

export default AnimatedBackground;
