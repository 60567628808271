import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0D0D0D",
    },
    secondary: {
      main: "#36454F",
    },
    tertiary: {
      main: "#e3242b",
    },
  },
  typography: {
    h4: {
      fontSize: "2.0rem",
      fontWeight: 600,
      lineHeight: 1.0,
      textAlign: "center",
      color: "black",
    },
    subtitle1: {
      fontSize: "1.2rem",
      fontWeight: 400,
      lineHeight: 1.0,
      textAlign: "center",
    },
    caption: {
      fontSize: "1.0rem",
      fontWeight: 400,
      lineHeight: 1.0,
      textAlign: "center",
    },
    body1: {
      fontSize: "1.0rem",
      fontWeight: 400,
      lineHeight: 1.4,
    },
    button: {
      textTransform: "none",
      padding: "8px 16px",
      border: "1px solid transparent",
      fontWeight: "500",
      letterSpacing: "0.5px",
      height: "2.5rem",
      width: "12rem",
      alignContent: "center",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "0.3rem",
          backgroundColor: "#0D0D0D",
          "&:hover": {
            backgroundColor: "#36454F",
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          "&:hover:not(.Mui-disabled):before": {
            borderBottom: `2px solid #36454F`,
          },
        },
        underline: {
          "&:before": {
            borderBottom: `1px solid #0D0D0D`,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "0.6rem",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#0D0D0D",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#36454F",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#36454F",
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#0D0D0D",
          color: "#36454F",
        },
      },
    },
  },
});

export default theme;
