import React, { useEffect } from "react";
import { useLocalStorage } from "@uidotdev/usehooks";
import { Box, Button, Typography } from "@mui/material";

import { trackEvent } from "../utils/Mixpanel";

const Error: React.FC = () => {
  const [, setToken] = useLocalStorage("token", null);

  const handleTryAgain = () => {
    trackEvent("error_clicked_try_again_button");

    setToken(null);

    window.location.href = "/";
  };

  useEffect(() => {
    trackEvent("error_page_entry");
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        paddingTop: "280px",
        width: "70%",
        margin: "auto",
      }}
    >
      <Box>
        <Typography variant="h4" gutterBottom>
          Uh oh!
        </Typography>

        <Typography variant="subtitle1">We hit a snag</Typography>
      </Box>

      <Button
        variant="contained"
        onClick={handleTryAgain}
        sx={{
          position: "absolute",
          bottom: "30px",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        Try again
      </Button>
    </Box>
  );
};

export default Error;
