import mixpanel from "mixpanel-browser";

const mixpanelToken: string | undefined = process.env.REACT_APP_MIXPANEL_TOKEN;
if (!mixpanelToken) {
  throw new Error("REACT_APP_MIXPANEL_TOKEN environment variable is not set.");
}

mixpanel.init(mixpanelToken, { debug: false });

export const trackEvent = (
  eventName: string,
  props: Record<string, any> = {},
  userId: string | null = null
): void => {
  if (userId) {
    mixpanel.identify(userId);
  }

  mixpanel.track(eventName, props);
};
